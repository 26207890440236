export const dictionary = {
  defaultLanguage: 'cs',
  cs: {
    button: 'Zavřít',
    mainTitle: 'Poskytnutí polohy',
    content: [
      'Na vašem prohlížeči nemáte zapnuté polohové služby. Pro zapnutí polohových služeb využijte ',
      'návod',
      '.',
    ],
  },
  en: {
    button: 'Close',
    mainTitle: 'Provide location',
    content: ['Location services are not allowed in your browser.To allow location services, use the ', 'manual', '.'],
  },
  uk: {
    button: 'Закрити',
    mainTitle: 'Надання місця розташування',
    content: [
      'У вашому браузері не ввімкнено служби локації. Використовуйте ',
      'інструкції',
      ', щоб увімкнути служби локації.',
    ],
  },
};
