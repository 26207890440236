import Listener from '../Listener/Listener.js';
import { removeClass, dispatchEvent, addClass, createElement, appendChilds } from '../Tools/Tools.js';
import { PubSub } from '../Tools/PubSub.js';
import { dictionary } from '../Language/dictionary.js';
import { EVENT_LANGUAGE_CHANGE, LANGUAGE } from '../constants.js';

/**
 * This class Dialog contains main methods for creating dialog. This class is used as extender.
 * @example
 * class TimeoutDialog extends Dialog {
 *     constructor(server, title) {
 *         super();
 *     }
 * }
 */
class Dialog {
  /**
   * This is constructor of Dialog. Sets important public and private variables which are used in Dialog.
   * @param {String} server - Server used for image urls. It can be for example www.page.com (//${this._server}/....).
   */
  constructor(server, type) {
    /** @type {Object} */
    this._dialog = null;
    /** @type {Object} */
    this.content = null;
    /** @type {Object} */
    this._parent = null;
    /** @type {Object} */
    this._overlay = null;
    /** @type {Object} */
    this._button = null;
    /** @type {Object} */
    this._closeButton = null;
    /** @type {String} */
    this._type = type;
    /** @type {String} */
    this.server = server;
    /** @type {Array} */
    this._listeners = [];
    /** @type {Boolean} */
    this.isCreated = false;
    /** @type {String} */
    this.language = PubSub.getFromStore(LANGUAGE);
    // Subscribe to language change event
    PubSub.subscribe(EVENT_LANGUAGE_CHANGE, this._setLanguage.bind(this));
  }

  /**
   * This method removes all listeners created for dialog.
   */
  _clear() {
    while (this._listeners.length !== 0) {
      this._listeners[0].stop();
      this._listeners.shift();
    }
  }

  /**
   * This method is event handler for dialog closing. Dialog will be closed by click or esc push.
   * @param {Object} e - This is event object.
   */
  _onClose(e) {
    if ((e.type === 'keyup' && e.keyCode === 27) || e.type === 'click') {
      let closeElemenet = '';

      if (e.target.classList && e.target.classList.contains('szn-geo-button')) {
        closeElemenet = 'button';
        if (e.target.classList.contains('gray')) {
          closeElemenet = 'close';
        }
      }
      this.close(closeElemenet);
    }
  }

  /**
   * This method create all HTML elements for dialog and set all listeners.
   */
  create() {
    this._overlay = createElement('div', { class: 'szn-geo-blur' });
    this._button = createElement('button', { class: 'szn-geo-button' });
    this._parent = createElement('div', {
      class: 'szn-geo-dialog-root',
      style: 'visibility: hidden;',
    });
    this._content = createElement('div', { class: 'szn-geo-cnt' });
    this._dialog = createElement('div', { id: 'szn-geo-dialog' });
    this._closeButton = createElement('span', { class: 'szn-geo-close' });
    this._mainTitle = createElement('h3', { class: 'szn-geo-title' });
    const css = createElement('link', {
      rel: 'stylesheet',
      href: `https://${this.server}/static/css/szn-geo.css`,
    });
    const head = document.querySelector('head');
    const body = document.body;
    const buttonContent = createElement('div', { class: 'szn-geo-button-cnt' });

    head.insertBefore(css, head.firstChild);
    appendChilds(this._dialog, this._closeButton, this._mainTitle, this._content, buttonContent);
    appendChilds(buttonContent, this._button);
    appendChilds(this._parent, this._dialog);

    addClass(body, 'szn-geo-overlay');
    body.insertBefore(this._overlay, body.firstChild);
    body.insertBefore(this._parent, body.firstChild);

    css.onload = () => {
      this._resize(true);
      this._setListeners();
      this._parent.style.visibility = 'visible';
      this.isCreated = true;
    };
  }

  /**
   * This method resize dialog on window resize. In first run will be set up listener for window resize.
   * @param {Boolean} firstResize - In first run will be true.
   */
  _resize(firstResize = false) {
    const width = this._dialog.offsetWidth;
    const height = this._dialog.offsetHeight;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const left = Math.ceil((windowWidth - width) / 2);
    let top = Math.ceil((windowHeight - height) / 2);

    top = height >= windowHeight - 300 ? 150 : Math.floor((windowHeight - height) / 2);
    this._dialog.style.left = left + 'px';
    this._dialog.style.top = top + 'px';

    if (firstResize) {
      this._listeners.push(new Listener('resize', this._onResize.bind(this), window));
    }
  }

  /**
   * This method is event handler for window resize.
   */
  _onResize() {
    this._resize();
  }

  /**
   * This method set up all listeners which can be set up in one moment.
   */
  _setListeners() {
    this._listeners.push(new Listener('click', this._onClose.bind(this), this._button));
    this._listeners.push(new Listener('click', this._onClose.bind(this), this._overlay));
    this._listeners.push(new Listener('click', this._onClose.bind(this), this._closeButton));
    this._listeners.push(new Listener('keyup', this._onClose.bind(this), document));
  }

  /**
   * This method call other methods which creating dialog.
   */
  generate() {
    if (!this.isCreated) {
      this.create();
      this.populate();
    }
  }

  populate() {
    if (!dictionary[this.language]) {
      this.language = dictionary.defaultLanguage;
    }
    const texts = dictionary[this.language];
    const helplink =
      this._type === 'denied'
        ? 'https://napoveda.seznam.cz/cz/seznam/nastaveni-geolokace/'
        : 'https://napoveda.seznam.cz/cz/seznam/nastaveni-geolokace/#povoleni';

    this._mainTitle.innerText = texts.mainTitle;
    this._button.innerText = texts.button;
    this._button.title = texts.button;
    // eslint-disable-next-line max-len
    this._content.innerHTML = `${texts.content[0]}<a class="click" id="chr_denied_all" href="${helplink}" target="_blank">${texts.content[1]}</a>${texts.content[2]}`;
  }

  _setLanguage(newLanguage) {
    if (!dictionary[newLanguage]) {
      return;
    }
    this.language = newLanguage;
    if (this.isCreated) {
      this.populate();
    }
  }

  /**
   * This method close dialogue and sends 'geoCloseDialog' event for services which use dialogs.
   * @param {String} closeElemenet - This string contains element which been used for closing dialog.
   */
  close(closeElemenet = '') {
    if (this.isCreated) {
      this._parent.parentNode.removeChild(this._parent);
      this._overlay.parentNode.removeChild(this._overlay);
      removeClass(document.body, 'szn-geo-overlay');
      this._clear();
      dispatchEvent('geoCloseDialog', { sourceElement: closeElemenet });
      this.isCreated = false;
    }
  }
}

export default Dialog;
