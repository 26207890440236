/**
 * This class PositionWarning initialize warning for navigator.geolocation. It is like PositionError
 * @example
 * let warning = new PositionWarning('Warning message', 4);
 */
class PositionWarning {
  /**
   * This constructor define warning for navigator.geolocation
   * @external {MDN - PositionError} https://developer.mozilla.org/en-US/docs/Web/API/PositionError
   * @param {String} message - Warning message.
   * @param {Number} code - Warning code.
   */
  constructor(code, message) {
    /**
     * 4: Support warning
     * @type {Number}
     */
    this.code = code;
    /** @type {String} */
    this.message = message;
    this.SUPPORT = 4;
  }
}

export default PositionWarning;
