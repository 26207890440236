import PositionWarning from '../PositionWarning/PositionWarning.js';
import ExceptionHandler from '../ExceptionHandler/ExceptionHandler.js';
import CookieServer from '../CookieServer/CookieServer.js';
import OperatingSystem from '../OperatingSystem/OperatingSystem.js';
import { hit } from '../GeoDot/GeoDot.js';
import { dispatchEvent } from '../Tools/Tools.js';
import { PubSub } from '../Tools/PubSub.js';
import { EVENT_LANGUAGE_CHANGE, LANGUAGE } from '../constants.js';

/**
 * This class Geo contains navigator.geolocation methods and more.
 * Browser should support navigator.geolocation
 * @example
 * let geo = new Geo();
 */
class Geo {
  /**
   * This is constructor of geo. Initialize navigator.geolocation and confing for Geo.
   * @external {MDN - navigator.geolocation} https://developer.mozilla.org/en-US/docs/Web/API/Geolocation
   * @param {Object} conf - Configuration for geojs.
   * Takes parameters: {Boolean} showDialog, {Number} supportInterval, {String} server., {String} language
   */
  constructor(conf = {}) {
    /** @type {navigator.geolocation} */
    this._geolocation = navigator.geolocation;
    /**
     * @type {Object}
     * @property {Boolean} conf.showDialogs
     * True if we want throw default dialogs after Errors. By default true.
     * @property {Number} conf.supportInterval
     * Time to throw support error. By default 6000ms.
     * @property {String} conf.server
     * Server for hitting etc.
     * @property {String} conf.language
     * Dialog language.
     */
    this._conf = {
      ...conf,
      server: this._getServer(conf.server),
    };
    /** @type {CookieServer} */
    this._cookieServer = new CookieServer(this.conf.server);
    /** @type {OperatingSystem} */
    this._operatingSystem = new OperatingSystem();
    /**
     * Id of last {@link watchPosition}
     * @type {Number}
     */
    this.watchId = 0;
    /**
     * Time for invoke support dialog
     * @type {Object}
     */
    this._supportTimeout = null;
    /** @type {ExceptionHandler} */
    this._exception = null;
    for (const param in conf) {
      this.conf[param] = conf[param];
    }
    // Set "language" to store.
    PubSub.setToStore(LANGUAGE, this._conf.language);

    this.getCurrentPositionSilently = this.getCurrentPositionSilently.bind(this);
  }

  /**
   * This method is used to get the current position of the device.
   * @param {function} success - This function will be called if getCurrentPossition is success.
   * @param {function} error - This function will be called if getCurrentPossition is not success.
   * @param {Object} options - Options for location (enableHighAccuracy, timeout, maximumAge).
   * @example
   * function success(data) {
   *		console.log(data);
   *	}
   *
   *	function error(data) {
   *		console.log(data);
   *	}
   *
   *	const options = {
   *		'timeout': 10000
   *	};
   *
   *	this.getCurrentPosition(success, error, options);
   */
  getCurrentPosition(success, error, options = {}) {
    this._geolocation.getCurrentPosition(
      (position) => this._locationSuccess(position, success),
      (exception) => this._locationError(exception, error),
      options
    );
    if (!this._supportTimeout) {
      this._supportTimeout = setTimeout(this._support.bind(this), this.conf.supportInterval);
    }
  }

  /**
   * This method is used to getting the current position of the device in every position change.
   * Method returns id of watch. This id can be used for unregister the handler.
   * @param {function} success - This function will be called if getCurrentPossition is success.
   * @param {function} error - This function will be called if getCurrentPossition is not success.
   * @param {Object} options - Options for location (enableHighAccuracy, timeout, maximumAge).
   * @returns {number} - The function returns watch ID
   * @example
   * function success(data) {
   *		console.log(data);
   *	}
   *
   *	function error(data) {
   *		console.log(data);
   *	}
   *
   *	const options = {
   *		'timeout': 10000
   *	};
   *
   *	this.watchPosition(success, error, options);
   */
  watchPosition(success, error, options = {}) {
    this._watchId = this._geolocation.watchPosition(
      (position) => this._locationSuccess(position, success),
      (exception) => this._locationError(exception, error),
      options
    );
    if (!this._supportTimeout) {
      this._supportTimeout = setTimeout(this._support.bind(this), this.conf.supportInterval);
    }
    return this._watchId;
  }

  /**
   * This method is used to unregister watch handler.
   * @param {Number} id - Id of watch.
   * @example
   * function success(data) {
   *		console.log(data);
   *	}
   *
   *	function error(data) {
   *		console.log(data);
   *	}
   *
   *	const options = {
   *		'timeout': 10000
   *	};
   *
   *	const id = this.watchPosition(success, error, options);
   *	this.clearWatch(id);
   */
  clearWatch(id = this._watchId) {
    clearTimeout(this._supportTimeout);
    this._geolocation.clearWatch(id);
  }

  /**
   * This method is used to get the current position of the device only when geolocation permissions are granted.
   * @returns {Promise<{
   *   allowed: boolean,
   *   status: "granted" | "denied" | "prompt" | null,
   *   location: Object | null,
   *   error?: Error
   * }>} Location result object.
   * @example
   * this.getCurrentPositionSilently().then((result) => {
   *  console.log(result);
   * });
   */
  getCurrentPositionSilently() {
    return new Promise(async (resolve) => {
      const result = {
        allowed: false,
        status: null,
        location: null,
      };

      try {
        if (!navigator.permissions) {
          // Permissions API not supported
          resolve(result);
          return;
        }

        result.status = (await navigator.permissions.query({ name: 'geolocation' })).state;
        if (result.status !== 'granted') {
          resolve(result);
          return;
        }

        // Permission is granted
        result.allowed = true;

        this._geolocation.getCurrentPosition(
          (position) => {
            result.location = position.coords;
            resolve(result);
          },
          (error) => {
            result.error = error;
            resolve(result);
          }
        );
      } catch (error) {
        result.error = error;
        resolve(result);
      }
    });
  }

  /**
   * This method returns true if geolocation was enabled. This information is taken from local storage.
   * @return {Boolean} True if geolocation was enabled else false.
   * @example
   * if (this.isAllowed()) {
   *     // Do something
   * }
   */
  isAllowed() {
    return window.localStorage.getItem('allow') && window.localStorage.getItem('allow') === 'true';
  }

  /**
   * This method handle position success. Close dialog and call callback.
   * @param {Object} position - Users position.
   * @param {function} callback - Success callback function for {@link watchPosition} or {@link getCurrentPosition}.
   * @example
   * watchPosition(position => this._locationSuccess(position, success),
   *				 exception => this._locationError(exception, error),
   *				 options);
   */
  _locationSuccess(position, callback) {
    clearTimeout(this._supportTimeout);
    if (this._exception) {
      this._exception.closeDialog();
    }
    this._cookieServer.position = position;
    this._cookieServer.send();
    hit(this.isAllowed() ? 1 : 2);
    if (window.localStorage) {
      window.localStorage.setItem('allow', 'true');
    }
    callback(position);
  }

  /**
   * This method handle position error. Close and show dialog and call callback.
   * Also hit to the dot, save to the local storage and dispatch 'showdialog' event.
   * @param {PositionError} error - Positon error.
   * @param {function} callback - Error callback function for {@link watchPosition} or {@link getCurrentPosition}.
   * @example
   * watchPosition(position => this._locationSuccess(position, success),
   *				 exception => this._locationError(exception, error),
   *				 options);
   */
  _locationError(error, callback) {
    clearTimeout(this._supportTimeout);
    hit(0);
    if (window.localStorage) {
      window.localStorage.setItem('allow', 'false');
    }
    if (this._exception) {
      this._exception.closeDialog();
    }
    this._exception = new ExceptionHandler(error, this.conf.server);
    if (this.conf.showDialogs && !this._operatingSystem.isMobile()) {
      this._exception.showDialog();
    }
    dispatchEvent('showdialog', { error });
    callback(error);
  }

  /**
   * This method create support warning and show support dialog if is needed.
   * @example
   * setTimeout(this._support, 6000);
   */
  _support() {
    /** @type {PositionWarning} */
    const warning = new PositionWarning(4, `Getting position takes ${this.conf.supportInterval}s.`);

    this._exception = new ExceptionHandler(warning, this.conf.server);
    if (this.conf.showDialogs) {
      this._exception.showDialog();
    }
  }

  /**
   * Gets server hostname.
   * Firstly prefers dev and test.
   * Then returns custom hostname if specified, otherwise fallbacks to default produstion
   * @param {string} hostname
   */
  _getServer(hostname) {
    const hostnames = {
      prod: 'geo.seznam.cz',
      dev: 'geo.seznam.dev.dszn.cz',
      test: 'geo.seznam.test.dszn.cz',
    };
    const scriptHostname = window.location.hostname;

    if (scriptHostname.includes('dev.dszn.cz')) {
      return hostnames.dev;
    }

    if (scriptHostname.includes('test.dszn.cz')) {
      return hostnames.test;
    }

    if (!hostname) {
      return hostnames.prod;
    }

    return hostname;
  }

  /** @type {Object} */
  get conf() {
    return this._conf;
  }

  /** @type {Object} */
  set conf(value) {
    this._conf = value;
    for (const param in value) {
      this.conf[param] = value[param];
    }
  }

  set language(language) {
    PubSub.setToStore(LANGUAGE, language);
    PubSub.publish(EVENT_LANGUAGE_CHANGE, language);
  }
}

export default Geo;
