/**
 * This method append one or more HTML elements (childs) to HTML element (parent).
 * @param {Object} parent - HTML parent element
 * @param {Array} childs - Array of HTML elements
 * @example
 * const parent = document.body;
 * const child1 = document.body.querySelector('.class1');
 * const child2 = document.body.querySelector('.class2');
 *
 * appendsChilds(parent, child1, child2);
 */
const appendChilds = (parent, ...childs) => {
  childs.forEach((child) => {
    if (child && parent) {
      parent.appendChild(child);
    }
  });
};

/**
 * This method create HTML element.
 * @param {String} type - Type of HTML element (div, p, a, ...)
 * @param {Object} specifications - Object which contains attributes for HTML element (class, name, type, ...)
 * @return {Object}
 * @example
 * const newElement = createElement('div', {
 *     'class': 'class1 class2 class3',
 *     'data-id': 'div0001'
 * });
 */
const createElement = (type, specifications = {}) => {
  /** @type {Object} */
  const newElement = document.createElement(type);

  for (const attribute in specifications) {
    if (attribute === 'innerHTML' || attribute === 'innerText' || attribute === 'checked') {
      newElement[attribute] = specifications[attribute];
    } else {
      newElement.setAttribute(attribute, specifications[attribute]);
    }
  }
  return newElement;
};

/**
 * This method add listener for event on the HTML element or elements.
 * @param {String} event - Type of event
 * @param {function} fn - Method which handle event (callback)
 * @param {Array} elements - Array of HTML elements
 * @example
 * const button1 = document.body.querySelector('.class1');
 * const button2 = document.body.querySelector('.class2');
 * const showEvent = (e) => {
 * 		console.log(e);
 * }
 *
 * addListener('click', showEvent, button1, button2);
 */
const addListener = (event, fn, ...elements) => {
  elements.forEach((element) => element.addEventListener(event, fn));
};

/**
 * This method remove listener for event on the HTML element or elements.
 * @param {String} event - Type of event
 * @param {function} fn - Method which handle event (callback)
 * @param {Array} elements - Array of HTML elements
 * @example
 * const button1 = document.body.querySelector('.class1');
 * const button2 = document.body.querySelector('.class2');
 * const showEvent = (e) => {
 * 		console.log(e);
 * }
 *
 * addListener('click', showEvent, button1, button2);
 * removeListener('click', showEvent, button1, button2);
 */
const removeListener = (event, fn, ...elements) => {
  elements.forEach((element) => element.removeEventListener(event, fn));
};

/**
 * This method invoke custom event
 * @param {String} type - Name of invoked event.
 * @param {Object} data - Data which will be send to the event handler.
 * @example
 * addListener('showSomething', e => console.log(e.detail.message), document);
 * dispatchEvent('showSomething', {message: 'Hello!'});
 */
const dispatchEvent = (type, data = {}) => {
  let event = null;
  const parameters = {};

  try {
    parameters.detail = data;
    event = new CustomEvent(type, parameters);
    window.dispatchEvent(event);
  } catch (error) {
    parameters.type = type;
    event = parameters;
  }

  const eventType = `on${type.replace(/[^a-z]/gi)}`;

  if (eventType in window) {
    window[eventType](event);
  }
};

/**
 * This method convert NodeList to array because of browser compatibility.
 * @param {Object} array - Nodelist which be convert to array
 * @return {Array}
 * @example
 * const elements = document.querySelectorAll('p');
 * const elementsInArray = nodeListToArray(elements);
 */
const nodeListToArray = (array) => {
  return Array.prototype.slice.call(array);
};

/**
 * This method removes class from the HTML element.
 * @param {Object} element - HTML element.
 * @param {String} className - Class which will be delete.
 * @return {Boolean}
 * @example
 * const paragraph = document.querySelector('p.paragraph--bold');
 *
 * removeClass(paragraph, 'paragraph--bold');
 */
const removeClass = (element, className) => {
  /** @type {Array} */
  const classes = element.className.split(' ');
  /** @type {String} */
  let result = '';

  classes.forEach((cls, i) => {
    if (cls !== className) {
      result += i === classes.length - 1 ? cls : `${cls} `;
    }
  });
  element.className = result;
  return result !== '';
};

/**
 * This method add class to the HTML element.
 * @param {Object} element - HTML element.
 * @param {String} className - Class which will be delete.
 * @return {Boolean}
 * @example
 * const paragraph = document.querySelector('p');
 *
 * addClass(paragraph, 'paragraph--bold');
 */
const addClass = (element, className) => {
  /** @type {Array} */
  const classes = element.className.split(' ');
  /** @type {Boolean} */
  let contains = false;

  classes.forEach((cls) => {
    if (cls === className) {
      contains = true;
    }
  });
  if (!contains) {
    element.className += ` ${className}`;
  }
  return !contains;
};

/**
 * Gets cookie (example from w3schools).
 * @param {*} cname
 */
const getCookie = (cname) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

/**
 * Gets hostname without www.
 * @param {*} hostname
 * @returns {string}
 */
const omitWww = (hostname) => hostname.replace(/^www\./, '');

export {
  appendChilds,
  addClass,
  addListener,
  createElement,
  dispatchEvent,
  nodeListToArray,
  removeClass,
  removeListener,
  getCookie,
  omitWww,
};
