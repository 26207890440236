import { omitWww } from '../Tools/Tools';

const lang = {
  cs: 'cs',
  en: 'en',
  uk: 'uk',
};

export const getLanguageFromUrl = () => {
  const host = omitWww(window.location.hostname);
  for (const [key, val] of Object.entries(lang)) {
    if (new RegExp(`^${val}.`).test(host)) {
      return key;
    }
  }

  return lang.cs;
};
