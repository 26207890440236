import { getLanguageFromUrl } from '../Language/language';

const defaultConfig = {
  showDialogs: false,
  supportInterval: 6000,
  language: getLanguageFromUrl(),
};

export const getConfig = () => {
  return {
    ...defaultConfig,
    ...(window.geoConfig || {}),
  };
};
